import React from 'react';
import './ChatInput.css';

const ChatInput = () => {
  return (
    <div className="chatinput-bar">
      <input type="text" placeholder="Type a message..." className="chatinput" />
      <div className="chatinput-actions">
        <svg width="33" height="33" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.8787 18.9497L6.26902 21.2191C6.11255 21.2453 5.96572 21.3121 5.84328 21.413C5.72085 21.5138 5.62713 21.6452 5.57155 21.7937L0.878987 34.3645C0.430872 35.5209 1.6397 36.6231 2.74915 36.0684L35.2736 19.8061C35.4989 19.6936 35.6884 19.5206 35.8208 19.3065C35.9533 19.0923 36.0234 18.8455 36.0234 18.5937C36.0234 18.3419 35.9533 18.0951 35.8208 17.8809C35.6884 17.6668 35.4989 17.4937 35.2736 17.3813L2.74915 1.119C1.6397 0.564279 0.430872 1.66831 0.878987 2.82293L5.57336 15.3936C5.62867 15.5426 5.72228 15.6743 5.84473 15.7755C5.96718 15.8767 6.11416 15.9438 6.27083 15.9701L19.8805 18.2377C19.9643 18.2524 20.0402 18.2961 20.0949 18.3612C20.1496 18.4264 20.1795 18.5087 20.1795 18.5937C20.1795 18.6787 20.1496 18.761 20.0949 18.8261C20.0402 18.8912 19.9643 18.935 19.8805 18.9497H19.8787Z" fill="#8BABD8" />
        </svg>
      </div>
    </div>
  );
};

export default ChatInput;
