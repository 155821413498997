import React from 'react';
import './DetailBox.css';

const DetailBox = ({ heading, body, svg }) => {
    return (
        <div className="detail-box">
            <div className="detail-box-content">
                <img className="detail-box-svg" src={svg}></img>
                <div className="detail-box-body">{body}</div>
                <div className="detail-box-button">{heading}</div>
            </div>
        </div>
    );
};

export default DetailBox;
