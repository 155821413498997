import AuthDesignSection from './authDesignSection.svg'
import BackButtonIcon from './backButtonIcon.svg'
import GoogleIcon from './googleIcon.svg'
import EyeIcon from './eyeIcon.svg'
import EyeSlashIcon from './eyeSlashIcon.svg'
import LocationIcon from './locationIcon.svg'

import aeroPlane from './aeroPlane.svg'
import age from './age.svg'
import gender from './gender.svg'
import member from './member.svg'
import budget from './budget.svg'
import startDate from './startDate.svg'
import endDate from './endDate.svg'
import startLocation from './startLocation.svg'
import endLocation from './endLocation.svg'
export const SVG = {
  AuthDesignSection,
  BackButtonIcon,
  GoogleIcon,
  EyeIcon,
  EyeSlashIcon,
  aeroPlane,
  endLocation,
  startLocation,
  endDate,
  startDate,
  budget,
member,
age,
gender,
LocationIcon

}
